import { FC, ReactNode } from 'react';

import styles from './GridWrapper.module.scss';

interface Props {
    children?: ReactNode;
    classes?: string;
}

export const GridWrapper: FC<Props> = ({ children, classes }) => {
    return <div className={`${styles.GridWrapper} ${classes || ''}`}>{children}</div>;
};
