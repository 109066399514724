import dynamic from 'next/dynamic';

import { Link } from '@common/atoms/Link';

import styles from './PXRLogo.module.scss';

const PXRLogoSvg = dynamic(() => import('@common/logos/pxr-logo-light.svg'));

export const PXRLogo = () => (
    <Link href={'https://www.pxr.nl'} target="_blank" className={styles.PXRLogo}>
        <PXRLogoSvg />
    </Link>
);
