import { FC, memo, PropsWithChildren } from 'react';

import { GridWrapperLayout } from '../types';

import styles from './GridItem.module.scss';

interface Props extends PropsWithChildren {
    gridArea: GridWrapperLayout;
    title?: string;
}

const Component: FC<Props> = ({ children, gridArea, title }) => (
    <div
        className={`
            ${styles.GridItem}
            ${styles[gridArea]}
            `}
    >
        {title ? <h3>{title}</h3> : null}
        {children}
    </div>
);

Component.displayName = 'GridItem';
export const GridItem = memo(Component);
