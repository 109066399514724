import { FullContext } from '@common/clients/api';
import { SocialLinkNetwork, SocialLinkProps } from '@web/atoms/SocialLink';
import { Route } from '@web/routing';

export const getSocialLinks = (fullContext: FullContext): SocialLinkProps[] =>
    [
        fullContext.facebook
            ? { socialNetwork: SocialLinkNetwork.FACEBOOK, url: fullContext.facebook }
            : false,
        fullContext.twitter ? { socialNetwork: SocialLinkNetwork.TWITTER, url: fullContext.twitter } : false,
        fullContext.tiktok ? { socialNetwork: SocialLinkNetwork.TIKTOK, url: fullContext.tiktok } : false,
        fullContext.instagram
            ? { socialNetwork: SocialLinkNetwork.INSTAGRAM, url: fullContext.instagram }
            : false,
        fullContext.youtube ? { socialNetwork: SocialLinkNetwork.YOUTUBE, url: fullContext.youtube } : false,
        { socialNetwork: SocialLinkNetwork.RSS, url: Route.RssOverview },
    ].filter<SocialLinkProps>((props): props is SocialLinkProps => !!props);
