export const AppStoreLogo = () => (
    <svg width="120" height="40" viewBox="0 0 120 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M110.135 0.00013H9.53468C9.16798 0.00013 8.80568 0.00013 8.43995 0.00213C8.1338 0.00413 7.83009 0.00994 7.521 0.01483C6.84951 0.0227318 6.17961 0.0818063 5.5171 0.19154C4.85552 0.303663 4.21467 0.515046 3.61622 0.81854C3.0185 1.1246 2.47235 1.5223 1.99757 1.9972C1.5203 2.47077 1.12246 3.01815 0.81935 3.61829C0.5154 4.21724 0.304641 4.85907 0.19435 5.52161C0.0830109 6.18331 0.0230984 6.85265 0.01515 7.52361C0.00587 7.83021 0.00489 8.13783 0 8.44447V31.5587C0.00489 31.8692 0.00587 32.17 0.01515 32.4806C0.0231008 33.1516 0.0830134 33.8209 0.19435 34.4825C0.304336 35.1455 0.515108 35.7877 0.81935 36.3868C1.12233 36.985 1.52022 37.5302 1.99757 38.0011C2.47054 38.4781 3.01705 38.8761 3.61622 39.1798C4.21467 39.4841 4.85545 39.6968 5.5171 39.8106C6.17972 39.9195 6.84956 39.9786 7.521 39.9874C7.83009 39.9942 8.1338 39.9981 8.43995 39.9981C8.80567 40.0001 9.168 40.0001 9.53468 40.0001H110.135C110.494 40.0001 110.859 40.0001 111.219 39.9981C111.523 39.9981 111.836 39.9942 112.141 39.9874C112.811 39.9791 113.479 39.92 114.141 39.8106C114.804 39.696 115.448 39.4834 116.049 39.1798C116.647 38.8759 117.193 38.478 117.666 38.0011C118.142 37.5284 118.541 36.9836 118.848 36.3868C119.15 35.7872 119.358 35.1451 119.467 34.4825C119.578 33.8208 119.64 33.1516 119.652 32.4806C119.656 32.17 119.656 31.8692 119.656 31.5587C119.664 31.1954 119.664 30.8341 119.664 30.4649V9.53626C119.664 9.17005 119.664 8.80677 119.656 8.44447C119.656 8.13783 119.656 7.83021 119.652 7.52357C119.64 6.85255 119.578 6.18337 119.467 5.52157C119.358 4.85941 119.149 4.21763 118.848 3.61825C118.23 2.41533 117.252 1.43616 116.049 0.81845C115.448 0.515697 114.804 0.30437 114.141 0.19145C113.48 0.0812328 112.811 0.0221378 112.141 0.01469C111.836 0.00981 111.523 0.00395 111.219 0.002C110.859 0 110.494 0 110.135 0V0.00013Z"
            fill="#A6A6A6"
        />
        <path
            d="M8.44487 39.1251C8.14019 39.1251 7.84287 39.1212 7.54058 39.1144C6.91436 39.1063 6.2896 39.0517 5.67144 38.9513C5.09503 38.852 4.53664 38.6674 4.0147 38.4034C3.49754 38.1416 3.02585 37.7984 2.6177 37.3868C2.20364 36.9801 1.85891 36.5084 1.59719 35.9903C1.33258 35.4689 1.14945 34.91 1.05419 34.3331C0.951311 33.7132 0.895651 33.0864 0.887687 32.4581C0.881347 32.2472 0.873047 31.545 0.873047 31.545V8.44446C0.873047 8.44446 0.881887 7.75305 0.887737 7.54992C0.895363 6.9226 0.950699 6.29678 1.05327 5.67785C1.14871 5.09937 1.33197 4.53887 1.59673 4.01575C1.85749 3.49807 2.2003 3.02598 2.61187 2.6178C3.02297 2.20574 3.49617 1.86073 4.01421 1.59533C4.53495 1.33222 5.09228 1.14885 5.66753 1.05139C6.28772 0.949958 6.91465 0.895118 7.54304 0.887332L8.44536 0.875122H111.214L112.127 0.887822C112.75 0.895221 113.371 0.949572 113.985 1.05041C114.566 1.1491 115.13 1.33374 115.656 1.59826C116.694 2.13311 117.539 2.97929 118.071 4.01819C118.332 4.5377 118.512 5.09363 118.607 5.66711C118.71 6.29111 118.768 6.92186 118.78 7.55432C118.783 7.83752 118.783 8.14172 118.783 8.44446C118.791 8.81946 118.791 9.17639 118.791 9.53625V30.4649C118.791 30.8282 118.791 31.1827 118.783 31.5401C118.783 31.8653 118.783 32.1632 118.779 32.4698C118.768 33.091 118.711 33.7105 118.608 34.3233C118.515 34.9044 118.333 35.4676 118.068 35.9933C117.805 36.5057 117.462 36.9734 117.053 37.379C116.644 37.7928 116.172 38.1381 115.653 38.4015C115.128 38.6675 114.566 38.8529 113.985 38.9513C113.367 39.0523 112.742 39.1068 112.116 39.1144C111.823 39.1212 111.517 39.1251 111.219 39.1251L110.135 39.1271L8.44487 39.1251Z"
            fill="black"
        />
        <path
            d="M24.769 20.3008C24.7908 18.6043 25.6802 17.0373 27.1256 16.1488C26.2096 14.8407 24.7305 14.041 23.1344 13.9911C21.4552 13.8148 19.8272 14.9959 18.9715 14.9959C18.0992 14.9959 16.7817 14.0086 15.363 14.0378C13.4963 14.0981 11.7982 15.1337 10.8901 16.7656C8.95607 20.1141 10.3987 25.0351 12.2513 27.7417C13.1782 29.0671 14.2615 30.5475 15.6789 30.495C17.066 30.4375 17.584 29.6105 19.2583 29.6105C20.9171 29.6105 21.4031 30.495 22.8493 30.4616C24.3377 30.4375 25.2754 29.1304 26.1698 27.7925C26.8358 26.8481 27.3483 25.8044 27.6882 24.7C25.9205 23.9523 24.7711 22.2202 24.769 20.3008V20.3008Z"
            fill="white"
        />
        <path
            d="M22.0373 12.211C22.8489 11.2368 23.2487 9.98463 23.1519 8.7204C21.912 8.85062 20.7668 9.44318 19.9443 10.38C19.1321 11.3043 18.7207 12.5136 18.8006 13.7414C20.057 13.7543 21.25 13.1903 22.0373 12.211V12.211Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.5691 27.1397H42.3025L43.4382 30.4962H45.4773L40.9939 18.0782H38.9109L34.4275 30.4962H36.4324L37.5691 27.1397ZM41.8113 25.5909H38.0593L39.91 20.1436H39.9617L41.8113 25.5909Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.3816 30.5909C53.6541 30.5909 55.1599 28.7833 55.1599 25.9698C55.1599 23.1642 53.6453 21.3478 51.3474 21.3477C50.1651 21.3096 49.0574 21.9246 48.4646 22.9483H48.4305V21.4425H46.6316V33.4913H48.49V29.0069H48.533C49.1006 30.0371 50.207 30.6524 51.3816 30.5909ZM50.8572 22.9317C52.3025 22.9317 53.2498 24.1368 53.2498 25.9698C53.2498 27.8194 52.3025 29.0157 50.8572 29.0157C49.4373 29.0157 48.4822 27.794 48.4822 25.9698C48.4822 24.1622 49.4373 22.9317 50.8572 22.9317Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.3465 30.5909C63.6189 30.5909 65.1248 28.7833 65.1248 25.9698C65.1248 23.1641 63.6101 21.3477 61.3123 21.3477C60.1299 21.3096 59.0222 21.9246 58.4294 22.9483H58.3953V21.4425H56.5964V33.4913H58.4548V29.0069H58.4978C59.0654 30.0371 60.1719 30.6524 61.3465 30.5909ZM60.822 22.9317C62.2674 22.9317 63.2146 24.1368 63.2146 25.9698H63.2146C63.2146 27.8194 62.2674 29.0157 60.822 29.0157C59.4021 29.0157 58.447 27.794 58.447 25.9698C58.447 24.1622 59.4021 22.9317 60.822 22.9317Z"
            fill="white"
        />
        <path
            d="M71.7107 27.0362C71.8484 28.2677 73.0447 29.0762 74.6795 29.0762C76.2459 29.0762 77.3728 28.2677 77.3728 27.1573C77.3728 26.1934 76.6931 25.6163 75.0838 25.2208L73.4744 24.8331C71.1941 24.2823 70.1355 23.2159 70.1355 21.4854C70.1355 19.3428 72.0027 17.8712 74.6541 17.8712C77.2781 17.8712 79.0769 19.3428 79.1375 21.4854H77.2615C77.1492 20.2462 76.1248 19.4981 74.6277 19.4981C73.1306 19.4981 72.1062 20.255 72.1062 21.3565C72.1062 22.2344 72.7605 22.751 74.3611 23.1465L75.7292 23.4825C78.2771 24.085 79.3357 25.1085 79.3357 26.9248C79.3357 29.2481 77.4851 30.7032 74.5417 30.7032C71.7878 30.7032 69.9285 29.2823 69.8083 27.0362L71.7107 27.0362Z"
            fill="white"
        />
        <path
            d="M83.3464 19.2999V21.4425H85.0681V22.9142H83.3464V27.9054C83.3464 28.6808 83.6912 29.0421 84.448 29.0421C84.6524 29.0386 84.8564 29.0242 85.0593 28.9991V30.462C84.7191 30.5256 84.3732 30.5544 84.0271 30.5479C82.1941 30.5479 81.4792 29.8595 81.4792 28.1036V22.9142H80.1628V21.4425H81.4792V19.2999H83.3464Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.3591 21.3312C87.7429 21.3312 86.0652 23.1212 86.0652 25.9698C86.0652 28.8263 87.7263 30.6085 90.3591 30.6085C92.9929 30.6085 94.6541 28.8263 94.6541 25.9698C94.6541 23.1212 92.9841 21.3312 90.3591 21.3312ZM90.3591 22.8624C91.865 22.8624 92.7605 24.0157 92.7605 25.9698H92.7605C92.7605 27.9318 91.865 29.0763 90.3591 29.0763C88.8533 29.0763 87.9587 27.9318 87.9587 25.9698C87.9587 24.0245 88.8533 22.8624 90.3591 22.8624Z"
            fill="white"
        />
        <path
            d="M96.1863 21.4425H97.9587V22.9835H98.0017C98.2489 21.9915 99.1579 21.3088 100.179 21.3478C100.394 21.347 100.607 21.3703 100.816 21.4171V23.1554C100.546 23.0728 100.264 23.0349 99.9812 23.0431C99.4348 23.0209 98.9061 23.2387 98.5338 23.6391C98.1615 24.0396 97.9828 24.5828 98.0447 25.1261V30.4962H96.1863L96.1863 21.4425Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.486 30.6085C107.534 30.6085 109.135 29.4806 109.385 27.837L107.62 27.837C107.281 28.6771 106.431 29.195 105.529 29.1105C104.853 29.1354 104.199 28.8685 103.733 28.3779C103.267 27.8872 103.034 27.22 103.094 26.546V26.4337H109.488V25.797C109.488 23.0519 107.913 21.3312 105.408 21.3312C102.861 21.3312 101.218 23.173 101.218 26.0128C101.218 28.8439 102.852 30.6085 105.486 30.6085ZM107.629 25.1349L103.102 25.1349C103.101 24.5236 103.343 23.9371 103.776 23.5056C104.209 23.0741 104.797 22.8334 105.408 22.837C106.014 22.824 106.599 23.0646 107.02 23.5008C107.442 23.9369 107.662 24.5292 107.629 25.1349Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M39.9317 9.54902C39.3879 8.97469 38.6151 8.67453 37.8262 8.73115H35.6709V14.698H37.8262C39.6035 14.698 40.6338 13.6022 40.6338 11.696C40.7333 10.9113 40.4756 10.1233 39.9317 9.54902ZM37.7226 13.8541H36.5976V9.57436H37.7226C38.2835 9.54323 38.8289 9.76437 39.2096 10.1773C39.5904 10.5902 39.7668 11.1517 39.6904 11.7081C39.7728 12.2669 39.5989 12.8331 39.2172 13.2494C38.8355 13.6657 38.2865 13.8878 37.7226 13.8541Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M42.6795 10.4287C41.9905 10.8562 41.6035 11.6374 41.6807 12.4445C41.6021 13.2525 41.9887 14.0351 42.6783 14.4635C43.3678 14.892 44.2407 14.892 44.9303 14.4635C45.6198 14.0351 46.0065 13.2525 45.9278 12.4445C46.005 11.6374 45.618 10.8562 44.9291 10.4287C44.2401 10.0012 43.3684 10.0012 42.6795 10.4287ZM43.8057 10.8976C44.5753 10.8976 45.0137 11.4684 45.0137 12.4445H45.0137C45.0137 13.4245 44.5753 13.9948 43.8057 13.9948C43.0333 13.9948 42.5987 13.4284 42.5987 12.4445C42.5987 11.4684 43.0333 10.8976 43.8057 10.8976Z"
            fill="white"
        />
        <path
            d="M51.5732 14.6979H50.6514L49.7207 11.3815H49.6504L48.7236 14.6979H47.8105L46.5693 10.1949H47.4707L48.2773 13.6309H48.3438L49.2695 10.1949H50.1221L51.0479 13.6309H51.1182L51.9209 10.1949H52.8096L51.5732 14.6979Z"
            fill="white"
        />
        <path
            d="M53.8535 10.195H54.709V10.9103H54.7754C55.0063 10.3836 55.546 10.0614 56.1191 10.108C56.563 10.0747 56.9979 10.2449 57.3011 10.5707C57.6043 10.8965 57.7429 11.3426 57.6777 11.7828V14.6978H56.7891V12.006C56.7891 11.2824 56.4746 10.9225 55.8174 10.9225C55.5164 10.9085 55.2242 11.0266 55.0175 11.246C54.8109 11.4653 54.7103 11.7639 54.7422 12.0636V14.6979H53.8535L53.8535 10.195Z"
            fill="white"
        />
        <path d="M59.0938 8.43713H59.9824V14.6979H59.0938V8.43713Z" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.2167 10.4287C61.5277 10.8562 61.1407 11.6373 61.2178 12.4445C61.1392 13.2525 61.526 14.0351 62.2155 14.4635C62.9051 14.8919 63.7781 14.8919 64.4676 14.4635C65.1572 14.0351 65.544 13.2525 65.4654 12.4445C65.5425 11.6373 65.1555 10.8562 64.4665 10.4287C63.7775 10.0012 62.9057 10.0012 62.2167 10.4287ZM63.3428 10.8976C64.1123 10.8976 64.5508 11.4684 64.5508 12.4445H64.5508C64.5508 13.4245 64.1123 13.9948 63.3428 13.9948C62.5704 13.9948 62.1358 13.4284 62.1358 12.4445C62.1358 11.4684 62.5704 10.8976 63.3428 10.8976Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.0757 12.0803C67.0044 12.1467 66.4009 12.6139 66.4009 13.4245C66.4024 13.8088 66.5664 14.1746 66.8524 14.4313C67.1384 14.6881 67.5196 14.8119 67.9019 14.7721C68.4472 14.7973 68.9638 14.5272 69.2544 14.0651H69.3247V14.6979H70.1802V11.6213C70.1802 10.6701 69.5435 10.1081 68.4145 10.1081C67.3931 10.1081 66.6655 10.6042 66.5747 11.3776H67.4351C67.5337 11.0593 67.8774 10.8771 68.3735 10.8771C68.981 10.8771 69.2954 11.1457 69.2954 11.6213V12.0099L68.0757 12.0803ZM69.2954 12.6633V13.0397C69.276 13.3267 69.1409 13.5935 68.9211 13.7791C68.7013 13.9646 68.4156 14.0531 68.1294 14.0241C67.646 14.0241 67.2944 13.7883 67.2944 13.383C67.2944 12.986 67.5757 12.7751 68.1958 12.7336L69.2954 12.6633Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.2173 10.1203C72.0796 10.1203 71.3481 11.0216 71.3481 12.4445C71.3481 13.8707 72.0718 14.7721 73.2173 14.7721C73.7981 14.7935 74.3427 14.4909 74.6314 13.9864H74.7017V14.6979H75.5532V8.43713H74.6646V10.9103H74.5981C74.3298 10.4016 73.7918 10.0938 73.2173 10.1203ZM73.4693 13.9743C72.7163 13.9743 72.2661 13.3996 72.2661 12.4445H72.2661C72.2661 11.4972 72.7212 10.9186 73.4693 10.9186C74.2134 10.9186 74.6812 11.5099 74.6812 12.4484C74.6812 13.3913 74.2183 13.9743 73.4693 13.9743Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.6682 8.30772C79.4545 8.40768 79.3234 8.62792 79.3374 8.86343C79.3388 9.01254 79.401 9.15462 79.5096 9.25683C79.6182 9.35903 79.7637 9.41253 79.9126 9.40493C80.1486 9.40473 80.3605 9.2606 80.4474 9.04125C80.5343 8.8219 80.4786 8.57174 80.3068 8.41002C80.135 8.2483 79.8819 8.20776 79.6682 8.30772ZM80.355 10.195H79.4693V14.6979H80.355V10.195Z"
            fill="white"
        />
        <path
            d="M81.7886 10.195H82.644V10.9103H82.7105C82.9414 10.3836 83.481 10.0614 84.0542 10.108C84.498 10.0747 84.9329 10.2449 85.2361 10.5707C85.5393 10.8965 85.6779 11.3426 85.6128 11.7828V14.6978H84.7241V12.006C84.7241 11.2824 84.4097 10.9225 83.7524 10.9225C83.4514 10.9085 83.1593 11.0266 82.9526 11.246C82.7459 11.4653 82.6454 11.7639 82.6772 12.0636V14.6979H81.7886V10.195Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M91.0767 10.1203C89.939 10.1203 89.2075 11.0216 89.2075 12.4445C89.2075 13.8707 89.9311 14.7721 91.0767 14.7721C91.6574 14.7935 92.2021 14.4909 92.4907 13.9864H92.561V14.6979H93.4126V8.43713H92.5239V10.9103H92.4575C92.1892 10.4016 91.6511 10.0938 91.0767 10.1203ZM91.3286 13.9743C90.5757 13.9743 90.1255 13.3996 90.1255 12.4445H90.1255C90.1255 11.4972 90.5806 10.9186 91.3286 10.9186C92.0728 10.9186 92.5406 11.5099 92.5406 12.4484C92.5406 13.3913 92.0776 13.9743 91.3286 13.9743Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.727 14.7848C97.607 14.8802 98.4289 14.3314 98.6781 13.4821L97.8227 13.482C97.6094 13.8645 97.1862 14.0801 96.7514 14.0279C96.4161 14.0317 96.0948 13.8938 95.8665 13.6482C95.6382 13.4026 95.524 13.0721 95.5522 12.7379V12.6881H98.7319V12.378C98.7319 10.964 97.976 10.108 96.7231 10.108C96.1211 10.1029 95.5465 10.3592 95.1482 10.8106C94.7499 11.2619 94.567 11.8639 94.6469 12.4606C94.5648 13.0556 94.7484 13.6566 95.1489 14.1042C95.5495 14.5518 96.1265 14.8006 96.727 14.7848ZM97.8266 12.0309H95.5522C95.5481 11.7204 95.6697 11.4214 95.8892 11.2018C96.1087 10.9822 96.4076 10.8605 96.7182 10.8644C97.0245 10.8574 97.3196 10.98 97.5307 11.2022C97.7418 11.4243 97.8492 11.7253 97.8266 12.0309Z"
            fill="white"
        />
    </svg>
);
